import type {QueryClient} from '@tanstack/react-query'
import * as cookie from 'cookie'
import escapeHtml from 'escape-html'
import {BlockName, getBlock, getPalettes} from 'quickstart'
import {StandaloneMarkdownEditor} from 'quickstart/blocks/admin/components/MarkdownEditor/standalone'
import {installCustomElements} from 'quickstart/elements'
import * as H from 'quickstart/hacks'
import {allHacks, fromServer} from 'quickstart/hooks'
import {createStore} from 'quickstart/store'
import {AdminContextFromServer, BlockContextFromServer} from 'quickstart/types'
import {createRoot} from 'react-dom/client'
import {
  Nullish,
  TREE,
  dom,
  getAdminQueryClient,
  getQueryClient,
  logger,
  setDebug,
  storage,
  toTizraId,
  tryJson,
} from 'tizra'
import {getPersistentQueryClient} from 'tizra/react-query/persistent'
import {TabStructure, TabStructureContent, tabStructure} from './tab-structure'

const _LOG = logger('entry-client')

_LOG.log(`Tizra Evergreen ${import.meta.env.VITE_QUICKSTART_VERSION}`)

let logCounter = 0

const LOG = {
  logger: (s: string) => _LOG.logger(`${s}:${logCounter++}`),
}

const originals = new WeakMap<HTMLFormElement>()

const renderBlockAdmin = ({
  adminName = 'admin',
  blockName,
  context,
  heading,
  queryClient = getAdminQueryClient({context}),
  root,
  wrapper,
  textarea = wrapper?.getElementsByTagName(
    'textarea',
  )[0] as HTMLTextAreaElement,
}: {
  blockName: BlockName
  root: HTMLElement
  textarea?: HTMLTextAreaElement
  context: AdminContextFromServer
  adminName?: 'admin' | 'globalAdmin'
  heading?: HTMLElement
  queryClient?: QueryClient
  wrapper?: HTMLElement
}) => {
  const log = LOG.logger(`renderBlockAdmin:${blockName}:${adminName}`)

  const fail = (...args: any) => {
    if (args.length) {
      log.error(...args)
    }
    // Since we couldn't render the react app, display the JSON config
    // instead.
    wrapper?.style.removeProperty('display')
  }

  // Look up the Quickstart block by name. This can fail for non-Quickstart
  // templates, or for blocks that aren't supported by the current Quickstart
  // template version.
  const qb = getBlock(blockName)
  if (!qb) {
    return fail(`Failed getBlock ${blockName}`)
  }

  // If we were passed a heading element, update it with the block title.
  if (heading) {
    heading.innerText = qb.title
  }

  // Prepare to disconnect observers and unrender.
  const cleanups: Array<() => void> = []
  const attempt = (fn: () => void) => {
    try {
      fn()
    } catch (e) {
      log.error(e)
    }
  }
  const teardown = () => {
    cleanups.splice(0).forEach(attempt)
  }

  // Attempt to render the Quickstart block admin form on the root node.
  // It will record changes back to the textarea containing the JSON
  // config.
  try {
    const unrender = qb[adminName]!({root, textarea, context, queryClient})
    cleanups.push(() => {
      log.log('calling unrender')
      unrender()
    })
  } catch (e) {
    return fail('failed to render', e)
  }

  // Detect when admin.js hides the dialog (block config) or removes our root
  // from the DOM (global config).
  const dialog = root.closest<HTMLDivElement>('.dialogbox')
  if (dialog) {
    cleanups.unshift(dom.onHide({node: dialog, callback: teardown, log}))
  } else if (adminName !== 'globalAdmin') {
    log.warn("couldn't find parent dialogbox")
  }
  cleanups.unshift(dom.onRemove({node: root, callback: teardown, log}))

  // On form submit, we need to unrender proactively, to avoid including
  // react-rendered fields in the submitted form.
  const form = textarea.closest('form')
  if (form) {
    const wrapped = form.onsubmit

    // Wrap form submit handler. Because there may be multiple global admins
    // rendered on a tab, this might wrap multiple times. That's okay, each
    // wrapper calls the previous, and teardown() restores the original
    // form.submit via cleanups.
    form.onsubmit = function (...args) {
      log.log('detected submit')
      teardown()
      if (wrapped) {
        return wrapped.apply(this, args)
      }
    }

    // Form gets reused, make sure to restore original handler no matter how it
    // is closed or discarded.
    if (!originals.has(form)) {
      originals.set(form, wrapped)
    }
    cleanups.push(() => {
      const orig = originals.get(form)
      if (orig) {
        originals.delete(form)
        form.onsubmit = orig
      }
    })
  } else {
    log.error("can't find form to wrap submit")
  }
}

const renderDesignTabContent = (props: {
  content: TabStructureContent
  context: AdminContextFromServer
  form: HTMLFormElement
  queryClient: QueryClient
  wrapper: HTMLElement
}) => {
  const log = LOG.logger(`renderDesignTabContent`)
  log.debug?.(props)
  const {
    content,
    content: {contents = [], globalAdmin, params, toggle},
    context,
    form,
    queryClient,
    wrapper,
  } = props

  // This is easiest to understand by looking at tab-structure.ts.
  if (toggle) {
    // Add two tbody tags in sequence. The first will be the toggle that shows
    // and hides the second.
    const [first, second] = ['tbody', 'tbody'].map(tagName =>
      wrapper.appendChild(document.createElement(tagName)),
    )

    // Build the toggle. This works similarly to existing toggles in the admin.
    first.innerHTML = `
        <tr>
          <td colspan="3">
            <h2>
              <a href="#">
                ${escapeHtml(toggle)}
              </a>
            </h2>
          </td>
        </tr>
      `
    first.querySelector('a')!.onclick = e => {
      e.preventDefault()
      second.style.display = second.style.display === 'none' ? '' : 'none'
    }

    // Build the content that is hidden and shown by the toggle.
    // This recurses into renderDesignTabContent.
    contents.forEach(content =>
      renderDesignTabContent({
        content,
        context,
        form,
        queryClient,
        wrapper: second,
      }),
    )
  } else if (globalAdmin) {
    // The toggle above has built a tbody structure, now fill it in with one or
    // more Evergreen-rendered global admin forms.
    const row = document.createElement('tr')
    row.innerHTML = `
      <td colspan="2"></td>
      <td></td>
    `
    if (wrapper.tagName === 'TBODY') {
      wrapper.appendChild(row)
    } else {
      const tbody = document.createElement('tbody')
      tbody.appendChild(row)
      wrapper.appendChild(tbody)
    }
    log.debug?.({row})
    renderBlockAdmin({
      adminName: 'globalAdmin',
      blockName: globalAdmin,
      context,
      queryClient,
      root: row.querySelector('td') as HTMLTableCellElement,
      textarea: form.querySelector(
        'tr[data-param-name="QuickstartGlobalConfig"] textarea',
      ) as HTMLTextAreaElement,
    })
  } else if (params) {
    const tbody =
      wrapper.tagName === 'TBODY' ?
        wrapper
      : wrapper.appendChild(document.createElement('tbody'))
    const rows = Array.from(
      form.querySelectorAll<HTMLTableRowElement>('tr[data-param-name]'),
    ).filter(
      ({dataset: {paramName}}) => paramName && params.includes(paramName),
    )
    rows.forEach(row => {
      tbody.appendChild(row)
      row.style.display = ''
    })
  } else {
    log.warn('unknown content:', content)
  }
}

const renderDesignTab = (props: {
  context: AdminContextFromServer
  form: HTMLFormElement
  viewId: keyof TabStructure
}) => {
  const log = LOG.logger('renderDesignTab')
  log.debug?.(props)
  const {context, form, viewId} = props
  const ts = tabStructure[viewId]
  if (!ts) {
    alert(`Quickstart.js doesn't know viewId: ${viewId}`)
    return
  }
  const queryClient = getAdminQueryClient({context})
  const wrapper = form.querySelector('table') as HTMLTableElement
  for (const content of ts.contents) {
    renderDesignTabContent({content, context, form, queryClient, wrapper})
  }
}

const renderBlocks = async ({blockContextJson}: {blockContextJson: string}) => {
  const log = LOG.logger('renderBlocks')
  const serverContext =
    tryJson<BlockContextFromServer>(blockContextJson, e =>
      log.error('failed to parse blockContextJson', e),
    ) || ({} as BlockContextFromServer)

  // This block context will be untracked, since this is not SSR.
  const context = fromServer(serverContext)

  // context.hacks is sparse, and normally checked via useHack()
  const hacks = allHacks(context.hacks)

  // Create the redux store for intra-block communication.
  const store = createStore()

  // Create the react-query client for API calls, shared by blocks on the page.
  let queryClient: QueryClient
  if (hacks.persist) {
    // Bust the persistent cache when LoginStatus changes.
    // https://github.com/Tizra/cubchicken/pull/724
    const cookieBuster = cookie.parse(document.cookie).LoginStatus ?? ''

    // Bust the persistent cache when there's a session error.
    const isError = !!context.sessionErrors.length
    const errorBusterKey = `${TREE}errorBuster`
    const errorBuster =
      isError ?
        storage.put(errorBusterKey, Date.now().toString())
      : storage.get(errorBusterKey) ?? ''

    // Bust the persistent cache on reload, either user-initiated or
    // programmatic. This triggers on simple reload, although it might still get
    // data that was cached at the HTTP layer if you don't shift-reload for
    // a hard refresh.
    const isReload = dom.navigationType() === 'reload'
    const {reloadBehavior} = hacks
    const reloadBusterKey = `${TREE}reloadBuster`
    const reloadBuster =
      reloadBehavior !== 'bust' ? ''
      : isReload ? storage.put(reloadBusterKey, Date.now().toString())
      : storage.get(reloadBusterKey) ?? ''

    const busters = {
      cookie: cookieBuster,
      error: errorBuster,
      reload: reloadBuster,
    }
    const buster = Object.entries(busters)
      .flatMap(([k, v]) => (v ? [`${k}:${v}`] : []))
      .join('|')
    log.debug?.({buster, busters, isError, isReload, reloadBehavior})

    queryClient = await getPersistentQueryClient({context, buster})
    if (isReload && reloadBehavior === 'invalidate') {
      queryClient.invalidateQueries()
    }
  } else {
    queryClient = getQueryClient({context})
  }

  // Prepare to render custom elements in Markdown.
  installCustomElements({context, queryClient})

  // Render the global block to a div at the end of body.
  const gbLog = log.logger('GlobalBlock')
  try {
    const root = document.createElement('div')
    root.className = 'quickstart-global'
    document.body.appendChild(root)
    getBlock('global').render({
      blockId: 'global',
      config: null,
      context,
      hydrate: false,
      queryClient,
      root,
      sortKey: 0,
      store,
    })
  } catch (e) {
    gbLog.error('failed to render', e)
  }

  // Render the theme block to a div at the end of body. Two things happen here:
  //
  // 1. The div is rendered with the content of ThemeBlock, which is probably
  //    just a <link> for the Google Font. This tag is redundant if SSR is
  //    enabled, because the ThemeBlock also renders in <head>, but it picks up
  //    the slack if SSR is disabled (by setting disable-ssr in QuickstartHacks)
  //
  // 2. ThemeBlock also renders <GlobalStyles>, which doesn't appear in the div.
  //    If SSR is enabled, rendering <GlobalStyles> will take over styling from
  //    the <style> tag in <head>
  //
  const tbLog = log.logger('ThemeBlock')
  try {
    // Make a root div for rendering at the end of body. Previously, before the
    // ThemeBlock rendered the <link> tag, this was just a disconnected div. Now
    // that it renders a <link>, it needs to be appended to body.
    const root = document.createElement('div')
    root.className = 'quickstart-theme'
    document.body.appendChild(root)
    getBlock('theme').render({
      blockId: 'theme',
      config: null,
      context,
      hydrate: false,
      queryClient,
      root,
      sortKey: 0,
      store,
    })
  } catch (e) {
    tbLog.error('failed to render', e)
  }

  // Render each block. These will hydrate if they were rendered on the server
  // side first.
  document
    .querySelectorAll<HTMLElement>('.t-quickstart-block > div[data-block-id]')
    .forEach((root, index) => {
      const blockId = root.dataset.blockId!
      const blockName = root.dataset.blockName as BlockName
      const blog = log.logger(blockName)
      const blockConfig = tryJson(root.dataset.blockConfig, e =>
        blog.error('failed to parse config', e),
      )
      try {
        const block = getBlock(blockName!)
        block.render({
          blockId,
          config: blockConfig ?? null,
          context,
          hydrate: root.hasChildNodes(),
          queryClient,
          root,
          sortKey: index + 1,
          store,
        })
      } catch (e) {
        blog.error('failed to render', e)
      }
    })
}

const replaced = new WeakSet<HTMLInputElement | HTMLTextAreaElement>()

const replaceField = ({
  context,
  input,
  savedValue,
  type,
}: {
  context: AdminContextFromServer
  input: HTMLInputElement | HTMLTextAreaElement
  savedValue: string | Nullish
  type: 'markdown' | unknown
}) => {
  const log = LOG.logger(`replaceField:${input.name || input.id}`)
  if (replaced.has(input)) {
    log.warn('ignoring previously replaced input', input)
    return
  }
  if (type === 'markdown') {
    const enabled = H.fromServer(context.hacks).lexical
    if (!enabled) return
  } else {
    log.warn('unknown type', type)
    return
  }
  const oldDisplay = input.style.display
  const div = document.createElement('div')
  const root = createRoot(div)

  // Prepare to disconnect observers and unrender.
  const cleanups: Array<() => void> = []
  const attempt = (fn: () => void) => {
    try {
      fn()
    } catch (e) {
      log.error(e)
    }
  }
  const restoreField = () => cleanups.splice(0).forEach(attempt)

  // On success or failure, show input, unmount root, and destroy div.
  cleanups.push(() => {
    attempt(() => {
      log.log('propagating highlight')
      input.classList.toggle(
        'highlighted',
        div.classList.contains('highlighted'),
      )
    })
    attempt(() => {
      if (oldDisplay !== 'none') {
        log.log('showing input')
        input.style.display = oldDisplay
      }
    })
    attempt(() => {
      log.log('unmounting')
      root.unmount()
    })
    attempt(() => {
      log.log('removing root div')
      div.remove()
    })
    replaced.delete(input)
  })

  // Can we find an objectId associated with this form?
  const form = input.closest('form')
  if (form && !context.tizraId) {
    const objectId = dom.formValue(form)('objectId')
    if (objectId) {
      context = {...context, tizraId: toTizraId(parseInt(objectId))}
    }
  }

  // Find or make a QueryClient, shared for this form.
  const queryClient = getAdminQueryClient({context, key: form})

  try {
    // Here goes nothing!
    replaced.add(input)

    // Admin is using box-sizing:content-box, so measure the textarea and apply
    // its width and height to our div.
    div.style.width = `${input.offsetWidth}px`
    div.style.minHeight = `${input.offsetHeight}px`

    // Propagate highlight.
    div.classList.toggle('highlighted', input.classList.contains('highlighted'))

    // Enable the admin to highlight the editor when checking for modified
    // fields. See turnOnText in admin.js. In theory this isn't necessary since
    // we're managing it directly by comparison to savedValue, but for the sake
    // of completeness...
    div.id = `${input.id}-wrapper`

    // Swap our div into place.
    input.before(div)
    input.style.display = 'none'

    // Watch for removal from DOM.
    cleanups.unshift(dom.onRemove({node: div, callback: restoreField, log}))

    // Finally ready to render.
    root.render(
      <StandaloneMarkdownEditor
        context={context}
        initialValue={input.value}
        onChange={v => {
          input.value = v
          if (typeof savedValue === 'string') {
            div.classList.toggle('highlighted', v !== savedValue)
          }
        }}
        onRender={() => {
          div.style.minHeight = ''
        }}
        queryClient={queryClient}
      />,
    )
  } catch (e) {
    log.error('failed to render', e)
    restoreField()
    return
  }

  return restoreField
}

const quickstart = {
  getBlock,
  getPalettes,
  renderBlockAdmin,
  renderBlocks,
  renderDesignTab,
  replaceField,
}

export type ClientEntryQuickstart = typeof quickstart

window.tizra ||= {} as Window['tizra']
window.tizra.setDebug = setDebug
window.tizra.quickstart ||= {} as Window['tizra']['quickstart']
Object.assign(window.tizra.quickstart, quickstart)
