import * as R from 'rambdax'
import {ListDefinition} from 'quickstart/components/layout/ListDefinition'
import {MetaName} from 'quickstart/components/tizra/MetaName'
import {MetaValue} from 'quickstart/components/tizra/MetaValue'
import {useHack, useMetaObjInfo} from 'quickstart/hooks'
import {ValueOf, truthy} from 'quickstart/types'
import {
  DisplayProps,
  MetaProps,
  getPropDisplays,
  partitionMetaDisplayProps,
} from 'quickstart/utils'
import {ComponentProps, ReactElement, forwardRef, useMemo} from 'react'
import {PropDef, logger, magicSort, sortBySortField} from 'tizra'

const log = logger('MetaList')

type UseMetaListProps = Omit<ComponentProps<typeof ListDefinition>, 'pairs'> &
  MetaProps &
  DisplayProps & {
    propNames: string[]
    sort?: boolean
  }

export const useMetaList = (
  {propNames: _propNames, sort = true, ..._rest}: UseMetaListProps,
  ref?: any,
) => {
  const [metaProps, displayProps, rest] = partitionMetaDisplayProps(_rest)
  const {metaObj, metaType, typeDefs} = useMetaObjInfo(metaProps)
  const br = useHack('br')
  const propDisplays = getPropDisplays(metaObj, typeDefs, {br, ...displayProps})
  const {propDefsIncludingSubtypes} = (metaType && typeDefs?.[metaType]) || {}

  const propNames = useMemo(() => {
    if (!propDefsIncludingSubtypes) return []
    const includeSet = new Set(_propNames)
    return R.piped(
      R.values(propDefsIncludingSubtypes),
      propDefs =>
        !sort ? propDefs : (
          R.piped(
            propDefs,
            magicSort<PropDef>(R.prop('displayName')),
            sortBySortField,
          )
        ),
      R.map<PropDef, string>(R.prop('name')),
      R.filter(name => includeSet.has(name)),
    )
  }, [propDefsIncludingSubtypes, sort, _propNames])

  const propList = useMemo<ValueOf<typeof propDisplays>[]>(
    () =>
      propNames
        .map(prop =>
          prop === '_name' ?
            metaType && typeDefs?.[metaType]?.namePropName
          : prop,
        )
        .filter(truthy)
        .map(prop => propDisplays[prop])
        .filter(truthy)
        .filter(pd => pd.count),
    [metaType, propNames, propDisplays, typeDefs],
  )

  if (propList.length === 0) {
    return null
  }

  return (
    <ListDefinition
      ref={ref}
      pairs={propList.map(({name}, i) => [
        <MetaName metaObj={metaObj} prop={name} key={`name-${i}`} />,
        <MetaValue metaObj={metaObj} prop={name} key={`value-${i}`} />,
      ])}
      {...rest}
    />
  )
}

type MetaListRender = (content: ReturnType<typeof useMetaList>) => ReactElement

interface MetaListProps extends UseMetaListProps {
  children?: MetaListRender
  render?: MetaListRender
}

export const MetaList = forwardRef<any, MetaListProps>(
  ({children, render = children, ...rest}, ref) => {
    const content = useMetaList(rest, ref)
    return render ? render(content) : content
  },
)
